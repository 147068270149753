function subject_select(object){var subject=object.value;if(subject=='HS-DIPLOMA-GED'||subject=='VOCATIONAL'){object.form.degree.options[0].selected=!0;object.form.degree.disabled=!0}
else{object.form.degree.disabled=!1}}
function getBaseURL(){var url=location.href;var baseURL=url.substring(0,url.indexOf('/',14));return baseURL}
function submitForm(){var base_url=getBaseURL();var form1=document.getElementById('searchschool_form');var deg=form1.degree.value.toLowerCase();var subj=form1.subject.value.toLowerCase();if(deg==''&&subj==''){url=base_url+'/online-colleges.html'}
else{if(deg=='')
deg='colleges';if(subj=='')
subj='colleges';url=base_url+'/online-'+deg+'/'+subj+'.html'}
window.location=url}
function submitZip(){var form=document.getElementById('searchzip_form');var zip=form.zip.value;var valid=/(^\d{5}$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} ?\d{1}[A-Za-z]{1}\d{1}$)/;if(zip=='')
{alert('Please enter a zip code.');form.zip.focus();return!1}
else{if(valid.test(zip)&&zip!=00000)
{form.submit();return!0}
else{alert("Please enter valid US or CA ZIP code");form.zip.focus();return!1}}
form.submit()}