var $=jQuery;var tag,moreLabel,lessLabel;function init_toggle(){if(!tag)tag=document.getElementById('moreText');if(!moreLabel)moreLabel=document.getElementById('moreToggle');if(!lessLabel)lessLabel=document.getElementById('lessToggle')}
function toggleMore(){init_toggle();tag.style.display='block';moreLabel.style.display='none'}
function toggleLess(){init_toggle();tag.style.display='none';moreLabel.style.display='block'}
function isValidEmailAddress(emailAddress){var pattern=new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);return pattern.test(emailAddress)}
function validate(){var name=document.getElementById('user_name');var email=document.getElementById('user_email');if(name.value==''){alert("Please enter your name.");name.focus();return!1}
if(validate_email(email.value)==!1){alert("Please enter a valid email address.");email.focus();return!1}
else return!0}
function validate_email(email){var reg=/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;if(reg.test(email)==!1){return!1}
else{return!0}}
toggleMoreListLocations=function(key){var hidden=$('#'+key).find('li.locationListHidden');if(hidden.length>0){hidden.attr('class','locationListShown');$('#'+key).parent().find('a.showHideLocations').html('[-] Hide..');return}
var shown=$('#'+key).find('li.locationListShown');if(shown.length>0){shown.attr('class','locationListHidden');$('#'+key).parent().find('a.showHideLocations').html('[+] More..');return}
return}
var QS_Download_Tracking={};(function($){QS_Download_Tracking={delayedTime:200,qsEducationKey:'qse_edu_',wtdlDownload:20,wtdlAnchorLinks:21};QS_Download_Tracking.itemTracker=function(){var dcsextParams={};dcsextParams['WT.dl']=QS_Download_Tracking.wtdlDownload;dcsextParams['DCSext.'+QS_Download_Tracking.qsEducationKey+'download_file']=$('#downloadFileName').val();dcsextParams['DCSext.'+QS_Download_Tracking.qsEducationKey+'env']=QS_Download_Tracking.getEnvName();var dcsextParamsArray=[];for(var key in dcsextParams){if(dcsextParams[key]&&parseInt(dcsextParams[key])!=-1){dcsextParamsArray.push(key);dcsextParamsArray.push(dcsextParams[key])}}
if(typeof dcsMultiTrack=='function'&&dcsextParamsArray.length>1){DCSext={};dcsMultiTrack.apply(this,dcsextParamsArray)}};QS_Download_Tracking.track=function($elm){var dcsextParams={};dcsextParams['WT.dl']=QS_Download_Tracking.wtdlAnchorLinks;dcsextParams['DCSext.'+QS_Download_Tracking.qsEducationKey+'env']=QS_Download_Tracking.getEnvName();var dataObj=$elm.data();$.each(dataObj,function(key,val){if(typeof val==='string'||typeof val==='number'){dcsextParams['DCSext.'+QS_Download_Tracking.qsEducationKey+key]=encodeURI(val)}});var dcsextParamsArray=[];for(var key in dcsextParams){if(dcsextParams[key]&&parseInt(dcsextParams[key])!=-1){dcsextParamsArray.push(key);dcsextParamsArray.push(dcsextParams[key])}}
if(typeof dcsMultiTrack=='function'&&dcsextParamsArray.length>1){DCSext={};dcsMultiTrack.apply(this,dcsextParamsArray)}};QS_Download_Tracking.getEnvName=function(){var env;var url=window.location.host;if(url.match(/lampdev/i)||url.match(/jbossdev/i)){env='dev'}
else if(url.match(/quinlampstage/i)||url.match(/quinstage/i)){env='stage'}
else if(url.match(/dev\./i)||url.match(/localhost/i)||url.match(/udev\./i)||url.match(/ulocal\./i)){env='localhost'}
else if(url.match(/jbosstest/i)){env='test'}
else{env='prod'}
return env};$(document).ready(function(){$('#downloadForm').submit(function(e){QS_Download_Tracking.itemTracker()})
$('.wtTracking').click(function(e){QS_Download_Tracking.track($(this))})})}(((typeof $!=='undefined')?$:undefined)))